import React from "react"
import ios_en from '../../../static/images/ios_en.svg'
import ios_ms from '../../../static/images/ios_ms.svg'
import ios_zh from '../../../static/images/ios_zh.svg'

const Image = ({intl, className}) => {
  let classNameCustom = `dowloadApp imgDowload dowloadAppAndroid ${className}`
  if(intl.locale == 'ms') return <img src={ios_ms} className={classNameCustom}/>
  if(intl.locale == 'zh') return <img src={ios_zh} className={classNameCustom}/>
  return <img src={ios_en} className={classNameCustom}/>
}

export default Image