import React from "react"
import android_en from '../../../static/images/android_en.svg'
import android_ms from '../../../static/images/android_ms.svg'
import android_zh from '../../../static/images/android_zh.svg'

const Image = ({intl, className}) => {
  let classNameCustom = `dowloadApp imgDowload dowloadAppAndroid ${className}`
  if(intl.locale == 'ms') return <img src={android_ms} className={classNameCustom}/>
  if(intl.locale == 'zh') return <img src={android_zh} className={classNameCustom}/>
  return <img src={android_en} className={classNameCustom}/>
}

export default Image