import React, { Component } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import BtnAndroid from '../../components/HImage/BtnDowloadAndroid';
import BtnIos from '../../components/HImage/BtnDownloadIOS';
import BtnHuawei from '../../components/HImage/BtnDownloadHuawei';
import link from '../../../config/link'
import SliderInput from '../../components/SliderInput/SliderInput'
import "react-multi-carousel/lib/styles.css";
import qrCode from '../../../static/images//qrcode.png'
import './calculator.scss';
import ButtonScroll from '../../components/ButtonScroll'
import CountryContext from '../../context/CountryContext'
import { Currencies } from '../../constants/commonData'
import { injectIntl } from "gatsby-plugin-intl"
class Header extends Component {
  static contextType = CountryContext;
	constructor(props) {
		super(props);
		this.state = {
      month: 6,
      referral: 0,
      booking: 0,
      fare: 0,
      total: 0
		}
  }
  
  changeValue = (value, name) => {
    this.setState({
      [name]: value
    })
  }

	render() {
    let { month, referral, booking, fare } = this.state;
    let {intl, countryCode} = this.props
    const {country, fleetInfo} = this.context || {}
    let total =(month * referral * booking * fare * 2/100).toLocaleString()
    if(country === 'gb') {
      total = (month * referral * booking * fare * 2.5/100).toLocaleString()
    }
    let context = this.context;
    let bannerDesktopUrl = `../../../${countryCode}/images/founder/calculator.png`
    let bannerMobileUrl  = `../../../${countryCode}/images/founder/calculator_mobile.png`
    if(countryCode === 'us' && context.fleetId === 'gojotexas') {
      bannerDesktopUrl = `../../../${countryCode}/images/founder/calculator_tx.png`
      bannerMobileUrl  = `../../../${countryCode}/images/founder/calculator_mobile_tx.png`
    }
		return (
      <div>
        <section id="banner_calculator">
          <div className="bannerImg">
            {/* <HImage filename="founder/calculator_mobile.png" className="imgBaner d-block d-md-none"/>
            <HImage filename="founder/calculator.png" className="imgBaner d-none d-md-block"/> */}
            <img src={bannerMobileUrl} className="imgBaner d-block d-md-none"/>
            <img src={bannerDesktopUrl} className="imgBaner d-none d-md-block"/>
          </div>
          <div className="contentBaner">
            <div className="checkImg">
              <Link to={`/${intl.locale}/`}>
                <img src="../../../images/logo.png"></img>
              </Link>
              <h3>{intl.formatMessage({id: "calculator.title"})}</h3>
            </div>
          </div>
        </section>
        <section id="calculator">
          <Container>
            <Row>
              <Col className="line_left" xs={12} md={6}>
                <div>
                  <label>{intl.formatMessage({id: "calculator.select"})}</label>
                  <div className="gr-select">
                    <button 
                      className={month == 6 ? "select-month active" :"select-month"} 
                      onClick={() => this.changeValue(6, "month")}
                    >
                      {intl.formatMessage({id: "calculator.6_months"})}
                    </button>
                    <button 
                      className={month == 12 ? "select-month active" :"select-month"} 
                      onClick={() => this.changeValue(12, "month")}
                    >
                      {intl.formatMessage({id: "calculator.12_months"})}
                    </button>
                  </div>
                </div>
                <div>
                  <SliderInput 
                    label={intl.formatMessage({id: "calculator.number"})}
                    min={0}
                    max={2000}
                    name="referral"
                    changeValue={this.changeValue}
                  />
                  <SliderInput 
                    label={intl.formatMessage({id: "calculator.ride"})}
                    min={0}
                    max={120}
                    name="booking"
                    changeValue={this.changeValue}
                  />
                  <SliderInput 
                    label={intl.formatMessage({id: "calculator.average"})}
                    min={0}
                    max={100}
                    name="fare"
                    allowDecimal
                    changeValue={this.changeValue}
                  />
                </div>
                {
                  total != "0" &&
                  <div className="totalFare d-block d-md-none">
                    <p className="fare">
                      <span className="fareSmall">{Currencies[countryCode]}</span>
                      <span className="fareLarge">{total}</span>
                    </p>
                    <p>{intl.formatMessage({id: "calculator.estimated"})}</p>
                  </div>
                }
                <div className="totalFare d-none d-md-block">
                  <p className="fare">
                    <span className="fareSmall">{Currencies[countryCode]}</span>
                    <span className="fareLarge">{total}</span>
                  </p>
                  <p>{intl.formatMessage({id: "calculator.estimated"})}</p>
                </div>
                <p className="note">{intl.formatMessage({id: "calculator.note"})}</p>
              </Col>
              <Col className="line_right" xs={12} md={6}>
                {/* for mobile */}
                <div className="dowload_calculator d-block d-md-none">
                  <ButtonScroll keyWord={intl.formatMessage({id: "button.becomeDriver"})} className={"btn_calculator"}/>
                  <div className="bt_store">
                      <a href={link.driverGooglePlay} className="linkApp" target="_blank">
                        <BtnAndroid intl={intl} className="dowloadApp dowloadAppAndroid"/>
                      </a>
                      <a href={link.driverAppStore} className="linkApp" target="_blank">
                        <BtnIos intl={intl} className="dowloadApp dowloadAppIos"/>
                      </a>
                      <a href={fleetInfo?.fleet?.socialSetting?.appGalleryDrv} className="linkApp" target="_blank">
                        <BtnHuawei intl={intl} className="dowloadApp dowloadAppIos"/>
                      </a>
                  </div>
                  <a target="_blank" href="https://gojo.global/" className="urlGojo">www.gojo.global</a>
                </div>

                {/* for Desktop */}
                {/* <div className="totalFare d-none d-md-block">
                  <p className="fare">
                    <span className="fareSmall">RM</span>
                    <span className="fareLarge">{total}</span>
                  </p>
                  <p>Estimated payout</p>
                </div> */}
                {
                  context.language == 'fr' 
                  ? <img className="d-none d-md-block gif-calculator" src={`../../../images/founder-calculator-fr.gif`}/>
                  : context.language == 'no'
                    ? <img className="d-none d-md-block gif-calculator" src={`../../../images/founder-calculator-no.gif`}/>
                    : <img className="d-none d-md-block gif-calculator" src={`../../../images/founder-calculator.gif`}/>
                }
                <div className="qrcode_container d-none d-md-block">
                  {context.language == 'en'
                   ? <p className="dowload_title">Download the <span style={{color: "#D6001A"}}>GOJO</span> Driver App Now!</p>
                   : context.language == 'fr'
                     ? <p className="dowload_title">Téléchargez l'application de pilote <span style={{color: "#D6001A"}}>GOJO</span> maintenant!</p>
                     : context.language == 'no'
                     ? <p className="dowload_title">Last ned <span style={{color: "#D6001A"}}>GOJO</span>  Driver-appen nå! </p>
                     : intl.formatMessage({id: "calculator.download"})
                  }
                  <div className="qr_content">
                <p className="scan">{intl.formatMessage({id: "calculator.qr"})}</p>
                    <div className="qrcode">
                      <div>
                        <img className="img_qrcode" src={qrCode}/>
                      </div>
                      <div className="link_or">
                        <div className="link_1"></div>
                        {/* <span className="or_download">or</span> */}
                        <div className="link_1"></div>
                      </div>
                      <div className="dowload_calculator">
                        <ButtonScroll keyWord={intl.formatMessage({id: "button.becomeDriver"})} className={"btn_calculator"}/>
                        <div className="bt_store">
                            <a href={link.driverGooglePlay} className="linkApp" target="_blank">
                              <BtnAndroid intl={intl} className="dowloadApp dowloadAppAndroid"/>
                            </a>
                            <a href={link.driverAppStore} className="linkApp" target="_blank">
                              <BtnIos intl={intl} className="dowloadApp dowloadAppIos"/>
                            </a>
                            <a href={fleetInfo?.fleet?.socialSetting?.appGalleryDrv} className="linkApp" target="_blank">
                              <BtnHuawei intl={intl} className="dowloadApp dowloadAppIos"/>
                            </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
		)
	}
}

export default injectIntl(Header)