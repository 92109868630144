import React, { useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HButton from '../../components/HButton/HButtonGradient';
import ButtonScroll from '../../components/ButtonScroll'
import HImage from '../../components/HImage/HImageByPath';
import link from '../../../config/link'

import "react-multi-carousel/lib/styles.css";
import BtnAndroid from '../../components/HImage/BtnDowloadAndroid';
import BtnIos from '../../components/HImage/BtnDownloadIOS';
import BtnHuawei from '../../components/HImage/BtnDownloadHuawei';
import CountryContext from '../../context/CountryContext'
import DirectionLanguage from '../../components/DirectionLanguage'
import { injectIntl } from "gatsby-plugin-intl"
import './founder.scss'
import fleetLogo from '../../../static/images/logo.png'
import fleetLogo2 from '../../../static/images/logo_2.png'

export default injectIntl(({ intl }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const Context = useContext(CountryContext)
  const languageCode = intl.locale || "en"
  const countryCode = Context.country
  const fleetInfo = Context.fleetInfo || {}
  let bannerDesktopUrl = `../../../${countryCode}/images/founder/banner_founder.png`
  let bannerMobileUrl = `../../../${countryCode}/images/founder/banner_founder_mobile.png`
  if (countryCode === 'us' && Context.fleetId === 'gojotexas') {
    bannerDesktopUrl = `../../../${countryCode}/images/founder/banner_founder_tx.png`
    bannerMobileUrl = `../../../${countryCode}/images/founder/banner_founder_mobile_tx.png`
  }
  return (
    <>
      <section id="founder">
        <div className="bannerImg">

          <img src={bannerMobileUrl} className="imgBaner d-block d-md-none" />
          <img src={bannerDesktopUrl} className="imgBaner d-none d-md-block" />
        </div>
        <div className="contentBaner">
          <div className="checkImg">
            {
              (countryCode != 'my' && countryCode != 'in') &&
              <img src={fleetLogo2} class="checkImg" />
            }
            {
              (countryCode == 'my' || countryCode == 'in') &&
              <img src={fleetLogo} class="checkImg" />
            }
            <p>{intl.formatMessage({ id: "founder.banner.presents" })}</p>
          </div>
          <h2>{intl.formatMessage({ id: "founder.banner.title" })}</h2>
          <h3>{intl.formatMessage({ id: "founder.banner.content" }, { br: <br /> })}</h3>
          <div className="banner_dowload">
            <ButtonScroll keyWord={intl.formatMessage({ id: "button.becomeDriver" })} />
          </div>
          <div className="bt_store">
            <a href={link.driverGooglePlay} className="linkApp" target="_blank">
              <BtnAndroid intl={intl} className="dowloadApp dowloadAppAndroid" />
            </a>
            <a href={link.driverAppStore} className="linkApp" target="_blank">
              <BtnIos intl={intl} className="dowloadApp dowloadAppIos" />
            </a>
            {
              fleetInfo?.fleet?.socialSetting?.appGalleryDrv &&
              <a href={fleetInfo?.fleet?.socialSetting?.appGalleryDrv} className="linkApp" target="_blank">
                <BtnHuawei intl={intl} className="dowloadApp dowloadAppIos" />
              </a>
            }
          </div>
        </div>
      </section>
      <section id="commitment">
        <Container>
          <Row className="row_Company">
            <Col xs={12} md={6} className="content_commitment">
              <div>
                <h2>{intl.formatMessage({ id: "founder.commitment.title" })}</h2>
                <p className="firt">{intl.formatMessage({ id: "founder.commitment.content_1" })}</p>
                {
                  intl.locale == "en"
                    ? <p>As a driver, you drive our mission forward on the road, and we want to ensure that your livelihood is safeguarded with the <b>Founder’s Fund, a 12-month profit-sharing initiative</b> that gives back a portion of our earnings on each eligible ride to GOJO Drivers.</p>
                    : <p>{intl.formatMessage({ id: "founder.commitment.content_2" })}</p>
                }
                <HButton
                  htmlType="link"
                  // href={"/founder's-fund-calculator"}
                  href={`/${languageCode}/founder's-fund-calculator`}
                  target={true}
                  keyWord={intl.formatMessage({ id: "founder.commitment.button" })}
                  className="hummingbird-header__signUpBtn link_calculator"
                />
              </div>
            </Col>
            <Col xs={12} md={6} className="image_fund">
              {
                countryCode == 'vn'
                  ? <img src="../../../services/fund.jpg" />
                  : <img src="../../../services/fund.gif" />
              }
            </Col>
          </Row>
        </Container>
      </section>
      <section id="how_founder">
        <div className="h-container">
          <h2>{intl.formatMessage({ id: "founder.howWork.title" })}</h2>
          <div className="be_driver_main">
            <div className="be_driver_content">
              <HImage filename="founder/be_founder_1.png" className="be_icon" />
              <DirectionLanguage>
                <h4>{intl.formatMessage({ id: "founder.howWork.content_1.title" }, { br: <br /> })}</h4>
              </DirectionLanguage>
              <p>{intl.formatMessage({ id: "founder.howWork.content_1.content" }, { br: <br /> })}</p>
            </div>
            <div className="be_driver_content">
              <HImage filename="founder/be_founder_2.png" className="be_icon" />
              <DirectionLanguage>
                <h4>{intl.formatMessage({ id: "founder.howWork.content_2.title" }, { br: <br /> })}</h4>
              </DirectionLanguage>
              <p>{intl.formatMessage({ id: "founder.howWork.content_2.content" }, { br: <br /> })}</p>
            </div>
            <div className="be_driver_content">
              <HImage filename="founder/be_founder_3.png" className="be_icon" />
              <DirectionLanguage>
                <h4>{intl.formatMessage({ id: "founder.howWork.content_3.title" }, { br: <br /> })}</h4>
              </DirectionLanguage>
              <p>{intl.formatMessage({ id: "founder.howWork.content_3.content" }, { br: <br /> })}</p>
            </div>
          </div>
          <div className="bt_store">
            <a href={link.driverGooglePlay} className="linkApp" target="_blank">
              <BtnAndroid intl={intl} className="dowloadApp dowloadAppAndroid" />
            </a>
            <a href={link.driverAppStore} className="linkApp" target="_blank">
              <BtnIos intl={intl} className="dowloadApp dowloadAppIos" />
            </a>
            {
              fleetInfo?.fleet?.socialSetting?.appGalleryDrv &&
              <a href={fleetInfo?.fleet?.socialSetting?.appGalleryDrv} className="linkApp" target="_blank">
                <BtnHuawei intl={intl} className="dowloadApp dowloadAppIos" />
              </a>
            }
          </div>
        </div>
      </section>
      <section id="power_founder">
        <div className="h-container">
          <h2>{intl.formatMessage({ id: "founder.refer.title" }, { br: <br /> })}</h2>
          <Row>
            <Col xs="12" md="7">
              <div className="video_power">
                <iframe
                  src={
                    countryCode == 'gb' ?
                      'https://www.youtube.com/embed/7BkNZh5o1Zs?&rel=0' :
                      'https://www.youtube.com/embed/ueEIVFSxm8k?&rel=0'
                  }
                  frameborder="0"
                  allow="accelerometer; rel; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  class="video"
                >
                </iframe>
              </div>
            </Col>
            <Col xs="12" md="5" className="col_power">
              <div className="power_content">
                <div>
                  <h4>{intl.formatMessage({ id: "founder.refer.title_sub" }, { br: <br /> })}</h4>
                  <p>{intl.formatMessage({ id: "founder.refer.content" }, { br: <br /> })}</p>
                  <ButtonScroll keyWord={intl.formatMessage({ id: "founder.refer.button" })} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
})